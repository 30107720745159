import { React, useEffect } from "react";
import classes from "./PageTemplate.module.css";
import PageTemplateContent from "./PageTemplateContent/PageTemplateContent";

const PageTemplate = (props) => {
  const classesToApply = [
    classes.pageTemplate,
  ];

  if (props.page == "ourProducts") {
    classesToApply.push(classes.pageTemplateOurProducts);
  }

    return (
      <div className={classesToApply.join(" ")}>
        <PageTemplateContent page={props.page} imageUrl={props.imageUrl} igmAlt={props.igmAlt} header={props.header} text={props.text} />
      </div>
    );
};

export default PageTemplate;
