import { React } from 'react'
import classes from './ClientsSay.module.css'
import ClientsSayImage from './ClientsSayImage/ClientsSayImage'
import citat1 from '../../images/citatbilder/citat1.png'
import citat2 from '../../images/citatbilder/citat2.png'
import citat3 from '../../images/citatbilder/citat3.png'
import citat4 from '../../images/citatbilder/citat4.png'
import citat5 from '../../images/citatbilder/citat5.png'
import citat6 from '../../images/citatbilder/citat6.png'
import citat7 from '../../images/citatbilder/citat7.png'
import citat8 from '../../images/citatbilder/citat8.png'
import citat9 from '../../images/citatbilder/citat9.png'
import citat10 from '../../images/citatbilder/citat10.png'
import citat11 from '../../images/citatbilder/citat11.png'
import citat12 from '../../images/citatbilder/citat12.png'

const clientsSayList = [
  {
    media_url: citat10,
    caption: 'Mycket trevlig tandborste som blev favorit direkt!',
  },
  {
    media_url: citat2,
    caption:
      'Min dotter älskade tandborsten. Den var lätt för henne att hålla i-bra utformad och väldigt söt, som ett plus i kanten.',
  },
  {
    media_url: citat3,
    caption:
      'Kanonskönt att det inte är plast så man känner naturen i materialet.',
  },
  {
    media_url: citat4,
    caption: 'Plötsligt tycker min treåring om att borsta tänderna!',
  },
  {
    media_url: citat5,
    caption:
      'Gullig tandborste i fin förpackning. Lagom mjuk för tänder och tandkött.',
  },
  {
    media_url: citat6,
    caption:
      'Dottern älskar den jättemkt. Lätt att hålla för henne. Även för mig. Så liten o nätt.',
  },
  {
    media_url: citat7,
    caption:
      'Mitt barn älskade den! Aldrig varit såhär enkelt att borsta tänderna!',
  },
  {
    media_url: citat8,
    caption:
      'Min dotter älskar denna tandborste och borstningen går som en dans just nu.',
  },
  {
    media_url: citat9,
    caption:
      'Superfint tryck på, och mjuk. Tycker om att den är hållbar och vegansk. Min son på 3.5 år tycker om att borsta med den också!',
  },
  {
    media_url: citat1,
    caption:
      'Att kunna värna om miljön samtidigt som barnet tycker det är roligt att borsta tänderna är bara positivt. Greppvänlig och fin att titta på.',
  },
  {
    media_url: citat11,
    caption:
      'Väldigt liten och smidigt tandborste. Supermjuk. Dottern gillar den!',
  },
  {
    media_url: citat12,
    caption:
      'Jättebra. Och bättre för naturen eftersom den är gjord av trä. Borsten är inte för hård eller för mjuk och känns bra på barnens tänder.',
  },
]

const ClientsSay = (props) => {
  return (
    <div className={classes.clientsSay}>
      <h1>Vad kunderna säger:</h1>
      <div className={classes.clientsSayInnerWrapper}>
        <div className={classes.clientsSayItemsContainer}>
          {clientsSayList.map((element, i) => {
            return (
              <ClientsSayImage
                key={i}
                mediaUrl={element.media_url}
                caption={element.caption}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ClientsSay
