import { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Context from "../../context/context";
import classes from "./FooterNavigation.module.css";
const FooterNavigation = () => {
  const {language} = useContext(Context);

  useEffect(() => {
  }, [language])
  return (
    <nav className={classes.nav}>
      <ul>
        <li>
          <NavLink
            to="/contact"
            >
            {language === "sv" ? "Kontakt" : "Contact"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/b2b"
          >
            {language === "sv" ? "B2B" : "B2B"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
          >
            {language === "sv" ? "Om oss" : "About Us"}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNavigation;
