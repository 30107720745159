import { useContext, useEffect, useState } from "react";
import Context from "../../context/context";
import classes from "./LanguageSelection.module.css";

const LanguageSelection = (props) => {
    const context = useContext(Context);
    useEffect(() => {
    }, [context.language])
    const changeLanguageHandler = (event) => context.setLanguage(event.target.value);

    return <div className={classes.LanguageSelection}>
      <select
        id="langageDropDown"
        onChange={(event) => changeLanguageHandler(event)}
      >
        <option value="sv">Svenska</option>
        <option value="eng">English</option>
      </select>
    </div>;
};

export default LanguageSelection;