import apotekhjartat from '../../images/suppliers/appotekhjartat.jpg'
import apotea from '../../images/suppliers/apotea.png'
import heltlogiskt from '../../images/suppliers/heltlogiskt.png'
import babyworld from '../../images/suppliers/babyworld.png'
import mundfrisk from '../../images/suppliers/LOGO_MundFrisk.png'
import kronan from '../../images/suppliers/kronan-logo.jpg'
import apohem from '../../images/suppliers/apohem.png'
import meds from '../../images/suppliers/meds.png'
import ica_toppen from '../../images/suppliers/ICA_Toppen.png'
import Supplier from './Supplier'
import classes from './Suppliers.module.css'

const Suppliers = (props) => {
  const getSuppliers = async () => {
    const fetchResult = await fetch(
      `http://localhost/getPageInfo/${props.page}`,
    )
    const data = await fetchResult.json()
    return data
  }

  const suppliers = [
    {
      id: 1,
      image: apotekhjartat,
      url: 'https://www.apotekhjartat.se/varumarken/easyfairy/',
      alt: 'Apotek Hjärtat',
      specialTreatment: false,
      specialPlacement: false,
    },
    { id: 2, image: apotea, url: 'https://www.apotea.se/easyfairy' },
    {
      id: 3,
      image: apohem,
      url: 'https://www.apohem.se/easyfairy',
      alt: 'ApoHem',
      specialTreatment: false,
      specialPlacement: false,
    },
    {
      id: 4,
      image: heltlogiskt,
      url: 'https://heltlogiskt.se/varumarken/easyfairy',
      alt: 'Helt Logiskt',
      specialTreatment: true,
      specialPlacement: false,
    },
    {
      id: 5,
      image: mundfrisk,
      url: 'https://www.mundfrisk.dk/shop/?brand=37582-easyfairy',
      alt: 'Mundfrisk',
      specialTreatment: false,
      specialPlacement: false,
    },
    {
      id: 6,
      image: kronan,
      url: 'https://www.kronansapotek.se/Varum%C3%A4rken/c/easyfairy',
      alt: 'Kronans Apotek',
      specialTreatment: true,
      specialPlacement: false,
    },
    {
      id: 7,
      image: babyworld,
      url: 'https://www.babyworld.se/varumarken/easyfairy',
      alt: 'Baby World',
      specialTreatment: false,
      specialPlacement: false,
    },
    {
      id: 8,
      image: meds,
      url: 'https://www.meds.se/easyfairy/',
      alt: 'Meds Apotek',
      specialTreatment: false,
      specialPlacement: false,
    },
    {
      id: 9,
      image: ica_toppen,
      alt: 'IcaToppen Höllviken',
      url: '',
      specialTreatment: false,
      specialPlacement: false,
    },
  ]

  return (
    <div className={classes.Suppliers}>
      {suppliers.map((element) => {
        return (
          <Supplier
            key={element.id}
            image={element.image}
            url={element.url}
            alt={element.alt}
            specialTreatment={element.specialTreatment}
            specialPlacement={element.specialPlacement}
          />
        )
      })}
    </div>
  )
}

export default Suppliers
