import { useContext, useEffect, useRef, useState } from "react";
import SlideShowImage from "../SlideShowImage/SlideShowImage";
import classes from "./SlideShow.module.css";
import img1 from "../../images/slideshow/Banner2.jpg";
import img2 from "../../images/slideshow/Banner3.jpg";
import img1Mobile from "../../images/slideshow/Banner1_mobile.jpg";
import img2Mobile from "../../images/slideshow/Banner2_mobile.jpg";
import img3Mobile from "../../images/slideshow/Banner3_mobile.jpg";
import Button from "../Button/Button";
import { useNavigate } from "react-router";
import Context from "../../context/context";
import { isMobile } from "react-device-detect";

const SlideShow = () => {
  const [index, setIndex] = useState(0);
  const [imageElements, setImageElements] = useState([]);
  const timeOutRef = useRef(null);
  const { language } = useContext(Context);


  const resetTimeout = () => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }
  };
  useEffect(() => {
    let images = [];
    if(!isMobile){
      images = [img1, img2];
      setImageElements([]);
      images.forEach((imageSource, i) => {
        setImageElements((prevImages) =>
          prevImages.concat(
            <SlideShowImage
              key={i}
              current={i}
              image={imageSource}
              imageShown={index}
            />
          )
        );
      });
    } else {
      images = [img1Mobile, img2Mobile];
      setImageElements([]);
      images.forEach((imageSource, i) => {
        setImageElements((prevImages) =>
          prevImages.concat(
            <SlideShowImage
              key={i}
              current={i}
              image={imageSource}
              imageShown={index}
            />
          )
        );
      });
    }

    timeOutRef.current = setTimeout(() => {
      resetTimeout();
      setIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => {
      resetTimeout();
    };
  }, [index]);

  const navigate = useNavigate();
  const onClickHandler = () =>{
    navigate("/ourProducts")
  }

  return (
    <div className={classes.slideShowContainer}>
      <section>{imageElements}</section>
      <div className={classes.buttonContainer}>
      <Button onClick={onClickHandler} buttonType="ordinaryButton" label=
    {(language === "sv") ? "Till kollektionen" : "Go to collection"} />
      </div>
    </div>
  );
};

export default SlideShow;
