import { useState, useEffect, useRef } from 'react'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import picture3to5Pink from '../../images/produktbilder/3-5_rosa_rak_BGR.jpg'
import picture3to5Turquoise from '../../images/produktbilder/3-5_turkos_rak_BGR.jpg'
import picture3to5Purple from '../../images/produktbilder/3-5_lila_rak_BGR.jpg'
import picture6to9Pink from '../../images/produktbilder/6-9_rosa_rak_BGR.jpg'
import picture6to9Green from '../../images/produktbilder/6-9_gron_rak_BGR.jpg'
import picture6to9Purple from '../../images/produktbilder/6-9_lila_rak_BGR.jpg'
import picture10to13Pink from '../../images/produktbilder/10-13_rosa_rak_BGR.jpg'
import picture10to13LightBlue from '../../images/produktbilder/10-13_ljusbla_rak_BGR.jpg'
import picture10to13Blue from '../../images/produktbilder/10-13_bla_rak_BGR.jpg'
import Product from '../../components/Products/Product'
import classes from './Carousel.module.css'

const Carousel = (props) => {
  const [xPosition, setXPosition] = useState(0)
  const [carouselElements, setCorouselElements] = useState([])
  const carouselElementRef = useRef(null)
  const [carouselElementWidth, setCarouselElementWidth] = useState()
  const carouselItemsContainerClasses = [classes.carouselItemsContainer]
  const carouselItemsClasses = [classes.carouselItems]
  const innerWrapperClasses = [classes.carouselInnerWrapper]
  const nextButtonClasses = [classes.nextButton]
  const prevButtonClasses = [classes.prevButton]
  if (props.ShowClientsSay) {
    prevButtonClasses.push(classes.prevButtonClientsSays)
    nextButtonClasses.push(classes.nextButtonClientsSays)
    carouselItemsContainerClasses.splice(0, 1)
    carouselItemsContainerClasses.push(classes.carouselClientsItemsContainer)
    carouselItemsClasses.push(classes.carouselItemsClientsSay)
    carouselItemsClasses.splice(0, 1)
    innerWrapperClasses.push(classes.carouselInnerWrapperClientSays)
    innerWrapperClasses.splice(0, 1)
  }
  const [yTouchStart, setYTuochStart] = useState(null)

  const onTouchStartHandler = (event) => {
    setYTuochStart(event.touches[0].clientY)
  }

  const onTouchMoveHandler = (event) => {
    if (!yTouchStart) {
      return
    }

    const touchEnd = event.touches[0].clientY

    const tochEndDiff = yTouchStart - touchEnd

    console.log('Moving diff', Math.abs(tochEndDiff), Math.abs(touchEnd))
    if (tochEndDiff > 0) {
      nextButtonHandler('prev')
    } else {
      nextButtonHandler('next')
    }
  }

  const nextButtonHandler = (direction) => {
    console.log(
      'ProductWidth',
      carouselElementWidth,
      'New xPosition',
      20 + carouselElementWidth,
    )
    const newPositionPlusMargin = props.ShowProducts
      ? carouselElementWidth + 20
      : carouselElementWidth
    const maxXPosNext =
      carouselElementWidth * carouselElements.length -
      /*(props.ShowProducts ?*/ carouselElementWidth * 4 //: carouselElementWidth)
    if (direction === 'next' && Math.abs(xPosition) < maxXPosNext) {
      setXPosition((prevPos) => prevPos - newPositionPlusMargin)
    } else if (direction === 'prev' && Math.abs(xPosition) > 0) {
      setXPosition((prevPos) => prevPos + newPositionPlusMargin)
    }
  }

  useEffect(() => {
    if (props.ShowProducts) {
      const productDescs = [
        { heading: '3-5 years', color: 'pink', picture: picture3to5Pink },
        {
          heading: '3-5 years',
          color: 'turquoise',
          picture: picture3to5Turquoise,
        },
        { heading: '3-5 years', color: 'purple', picture: picture3to5Purple },
        { heading: '6-9 years', color: 'pink', picture: picture6to9Pink },
        { heading: '6-9 years', color: 'green', picture: picture6to9Green },
        { heading: '6-9 years', color: 'purple', picture: picture6to9Purple },
        { heading: '10-13 years', color: 'pink', picture: picture10to13Pink },
        {
          heading: '10-13 years',
          color: 'turquoise',
          picture: picture10to13LightBlue,
        },
        { heading: '10-13 years', color: 'blue', picture: picture10to13Blue },
      ]
      setCorouselElements([])
      productDescs.forEach((productDesc, i) => {
        setCorouselElements((prevProducts) =>
          prevProducts.concat(
            <Product
              key={i}
              descHeading={productDesc.heading}
              descColor={productDesc.color}
              descPicture={productDesc.picture}
              style={{ transform: `${xPosition}` }}
            />,
          ),
        )
      })
      setCarouselElementWidth(221)
    }
  }, [carouselElementRef, props.ShowProducts, xPosition])

  useEffect(() => {
    console.log('xPosition=', xPosition)
  }, [xPosition])

  return (
    <div className={classes.carouselOuterWrapper}>
      {props.ShowClientsSay ? <h1>Vad kunderna säger:</h1> : null}
      <div className={innerWrapperClasses.join(" ")}>
        <span
          className={prevButtonClasses.join(" ")}
          onClick={() => nextButtonHandler('prev')}
        >
          <FontAwesomeIcon icon={faAngleLeft} size="2x" />
        </span>
        <section className={carouselItemsContainerClasses.join(' ')}>
          <section
            className={carouselItemsClasses.join(' ')}
            onTouchStart={(event) => onTouchStartHandler(event)}
            onTouchMove={(event) => onTouchMoveHandler(event)}
            style={{
              transform: `translate3d(${xPosition}px,0,0)`,
              whiteSpace: 'nowrap',
            }}
          >
            {carouselElements}
          </section>
        </section>
        <span
          className={nextButtonClasses.join(" ")}
          onClick={() => nextButtonHandler('next')}
        >
          <FontAwesomeIcon icon={faAngleRight} size="2x" />
        </span>
      </div>
    </div>
  )
}

export default Carousel
