import { useState } from "react";
import Context from "./context";

const ContextProvider = (props) => {
    const [language, setLanguage] = useState("sv");
    const setLanguageHandler = (newLanguage) => {
        console.log("Language being updated", newLanguage)
        setLanguage(() => newLanguage);
    }
    const defaultContext = {
        language,
        setLanguage: setLanguageHandler
    }
    return <Context.Provider value={defaultContext}>
        {props.children}
    </Context.Provider>
}

export default ContextProvider;