import "./App.css";
import { Navigate, Route, Routes } from "react-router";
import LandingPage from "./components/LandingPage/LandingPage";
import PageInfoFetcher from "./components/PageInfoFetcher/PageInfoFetcher";
import Layout from "./components/Layout/Layout";
import ContextProvider from "./context/contextProvider";

function App() {
  return (
    <ContextProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<Navigate replace to="/landing" />} />
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/ourProducts" element={<PageInfoFetcher page="ourProducts" />} />
          <Route
            path="/ourIcon"
            element={<PageInfoFetcher page="ourIcon" />}
          />
          <Route
            path="/ourStory"
            element={<PageInfoFetcher page="ourStory" />}
          />
          <Route
            path="/whyBambu"
            element={<PageInfoFetcher page="whyBambu" />}
          />
          <Route
            path="/dentistRecommends"
            element={<PageInfoFetcher page="dentistRecommends" />}
          />
          <Route
            path="/about"
            element={<PageInfoFetcher page="about" />}
          />
          <Route
            path="/b2b"
            element={<PageInfoFetcher page="b2b" />}
          />
          <Route
            path="/contact"
            element={<PageInfoFetcher page="contact" />}
          />
        </Routes>
      </Layout>
    </ContextProvider>
  );
}

export default App;
