import classes from "./Presentation.module.css";
import Context from "../../context/context";
import { useContext } from "react";


const Presentation = () => {
  const {language} = useContext(Context);
    return (
      <div className={classes.Presentation}>
        <h1>
          {language === "sv"
            ? "Bambutandborstar för barn"
            : "bamboo toothbrushes for kids"}
        </h1>
        <p>
          {language === "sv"
            ? "Ett miljövänligt alternativ! Vår ambition är att tandborstningen ska vara rolig och en naturlig vana. EasyFairys tandborstar är för barn i åldern 3-13 år och har ett anpassat handtag efter de olika åldrarna, likaså borsthuvud i olika storlek samt olika färgglada mjuka borststrån. Handtaget är prytt med ett sött kaninansikte med ögon, nos och tänder. Gör tandborstningen till en god vana inte bara för ditt barns munhälsa utan även för vår planet. Välj EasyFairy bambutandborste varje dag. Biologiskt nedbrytbara. BPA- och PCV-fria borststrån. Veganska. 100% plastfria förpackningar med print i sojabläck."
            : "An environmentally friendly alternative! Let your daily tooth brushing habit be fun and make sure to care for the environment at the same time. EasyFairy bamboo toothbrushes are made for children aged 3-13 years as our handles are adapted to the different ages. Also the brush heads are in different sizes with various colourful soft bristles. The handle is decorated with eyes, nose and teeth - our cute bunny face. Make tooth brushing a good habit, not only for your children's oral health but also for our planet. That is why you should choose an EasyFairy bamboo toothbrush every day. Our products are: biodegradable, vegan, BPA- and PCV-free bristles, and 100% plastic-free packaging with soy ink print. We love sustainable smiles, don’t you?"}
        </p>
      </div>
    );
}

export default Presentation;