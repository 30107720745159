import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import classes from "./Modal.module.css";
import InstagramModalTemplate from "./ModalTemplates/InstagramModalTemplate";

const Modal = (props) => {
  let attachedClasses = [classes.Modal, classes.Close];
  if (props.modalOpen) {
    attachedClasses = [classes.Modal, classes.Open];
  }

  useEffect(() => {
    console.log(props)
  }, [props.mediaUrl, props.caption, props.modalOpen])
  const modalTemplate = <InstagramModalTemplate mediaUrl={props.mediaUrl} caption={props.caption}/>

  return (
    <div className={attachedClasses.join(" ")}>
      <span className={classes.CloseButton} onClick={props.closeModal}>
      <FontAwesomeIcon icon={faXmark} size="2x" />
      </span>
      <div className={classes.Body}>{modalTemplate}</div>
    </div>
  );
};

export default Modal;
