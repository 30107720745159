import classes from "./Footer.module.css";
import FooterNavigation from "../../UI/Navigation/FooterNavigation";
import loggo from "../../images/loggo/EasyFairy_footer.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <footer className={classes.footer}>
      <span className={classes.loggoContainer}>
        <img src={loggo} className={classes.loggo} alt="EasyFairy" />
      </span>
      <FooterNavigation />
      <div className={classes.socialIcons}>
        <a href="https://www.instagram.com/easyfairy_official/" target="_blank">
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        <a href="https://www.tiktok.com/@easyfairy" target="_blank">
          <FontAwesomeIcon icon={faTiktok} size="2x" />
        </a>
      </div>
      <small className={classes.small}>&copy; 2022 All rights reserved</small>
    </footer>
  );
};

export default Footer;
