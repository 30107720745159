import { Fragment, useContext } from 'react'
import Presentation from '../Precentation/Presentation'
import Products from '../Products/Products'
import Suppliers from '../Suppliers/Suppliers'
import Instagram from '../Instagram/Instagram'
import ClientsSay from '../ClientsSay/ClientsSay'
import Carousel from '../../UI/Carousel/Carousel'
import Context from '../../context/context'

const LandingPage = (props) => {
  const { language } = useContext(Context)
  return (
    <Fragment>
      <Suppliers />
      <Carousel ShowProducts={true} />
      <Presentation />
      <Instagram />
      {language === 'sv' && <ClientsSay />}
    </Fragment>
  )
}

export default LandingPage
