import classes from "./PageTemplateContent.module.css";
import parse from "html-react-parser";
import { useEffect } from "react";

const PageTemplateContent = (props) => {
  const classesToApply = []
  if (props.page == "about" || props.page == "b2b" || props.page == "contact") {
    classesToApply.push(classes.noImgText);
    classesToApply.push(classes.noImg);
  } else{
    classesToApply.push(classes.text);
    classesToApply.push(classes.image);
  }

   return (
      <>
        <div className={classesToApply[0]}>
          <h1>{props.header}</h1>
          <p>{parse(props.text)}</p>
        </div>
        <div className={classesToApply[1]}>
          <img src={props.imageUrl} alt={props.igmAlt} />
        </div>
      </>
    );
}

export default PageTemplateContent;