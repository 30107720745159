import { useState, useEffect, useContext } from "react";
import classes from "./OurProducts.module.css";
import Context from "../../context/context";


const OurProducts = (props) => {
  const {language} = useContext(Context);
  const [teplateOfProduts, setTeplateOfProduts] = useState([])

    useEffect(() => {
      props.products.forEach((element, i) => {
        setTeplateOfProduts((prev) => {
          if(prev.length <=3){
          return [
          ...prev,
          <div key={i} className={classes.product}>
            <div className={classes.text}>
              <h1>
                {language === "sv" ? element.heading.sv : element.heading.eng}
              </h1>
              <p>{language === "sv" ? element.text.sv : element.text.eng}</p>
            </div>
            <div className={classes.image}>
              <img src={element.picture} alt={props.igmAlt} />
            </div>
          </div>,
        ]}
      else{
        prev = [];

        return [
          ...prev,
          <div key={i} className={classes.product}>
            <div className={classes.text}>
              <h1>
                {language === "sv" ? element.heading.sv : element.heading.eng}
              </h1>
              <p>{language === "sv" ? element.text.sv : element.text.eng}</p>
            </div>
            <div className={classes.image}>
              <img src={element.picture} alt={props.igmAlt} />
            </div>
          </div>,
        ];
      }
    });
      });
    }, [props.products, language]);

  return <div className={classes.ourProducts}>{teplateOfProduts}</div>;
}

export default OurProducts;
