import { React, useEffect } from "react";
import classes from "./Layout.module.css";
import Header from "../../UI/Header/Header";
import Footer from "../Footer/Footer";

const Layout = (props) => {
  return (
    <div className={classes.layout}>
      <Header />
      <main className={classes.mainContent}>{props.children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
