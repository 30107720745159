import { useEffect } from "react";
import classes from "./InstagramModalTemplate.module.css";

const InstagramModalTemplate = (props) => {

    useEffect(() => {}, [props.mediaUrl, props.caption])
    return (
      <div className={classes.InstagramModalTemplate}>
        <div className={classes.InstagramPicture}>
          <img src={props.mediaUrl} alt={props.caption} />
        </div>
        <div className={classes.Caption}>
          <p>{props.caption}</p>
        </div>
      </div>
    );
}

export default InstagramModalTemplate;