import { NavLink, Link } from "react-router-dom";
import classes from "./CompanyLogo.module.css";
import loggo from "../../images/loggo/EasyFairy.png";
const CompanyLogo = () => {
  return (
    <Link to="/">
      <div className={classes.CompanyLogo}>
          <img src={loggo} />
      </div>
    </Link>
  );
};

export default CompanyLogo;
