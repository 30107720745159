import { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Context from "../../context/context";
import classes from "./Navigation.module.css";

const Navigation = (props) => {
  const {language} = useContext(Context);

  
  let attachedClasses = [classes.nav, classes.close];

  if(props.isMenuOpen){
  attachedClasses = [classes.nav, classes.open];
  }

  useEffect(() => {
  }, [language])
  return (
    <>
      <nav className={attachedClasses.join(" ")}>
        <ul>
          <li>
            <NavLink
              to="/ourProducts"
              className={({ isActive }) => (isActive ? " active" : "")}
              onClick={props.clicked}
            >
              {language === "sv" ? "Våra produkter" : "Our products"}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/ourIcon"
              className={({ isActive }) => (isActive ? " active" : "")}
              onClick={props.clicked}
            >
              {language === "sv" ? "Vår ikon" : "Our icon"}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/ourStory"
              className={({ isActive }) => (isActive ? " active" : "")}
              onClick={props.clicked}
            >
              {language === "sv" ? "Vår historia" : "Our story"}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/whyBambu"
              className={({ isActive }) => (isActive ? " active" : "")}
              onClick={props.clicked}
            >
              {language === "sv" ? "Varför bambu?" : "Why bamboo?"}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/dentistRecommends"
              className={({ isActive }) => (isActive ? " active" : "")}
              onClick={props.clicked}
            >
              {language === "sv"
                ? "Tandläkaren rekommenderar"
                : "Dentist recommendatioins"}
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navigation;
