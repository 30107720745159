import { useContext, useEffect, useState } from "react";
import PageTemplate from "../PageTemplate/PageTemplate";
import loggo from "../../images/loggo/EasyFairy.png"
import icon from "../../images/loggo/EasyFairy_varIkon.jpg";
import varaProdukterBild from "../../images/Tandborstar_vara_produkter_blabgr.jpg"
import productImage35 from "../../images/produktbilder/Banner3-6.jpg";
import productImage69 from "../../images/produktbilder/Banner6-9.jpg";
import productImage1013 from "../../images/produktbilder/Banner10-13.jpg";

import OurProducts from "../Products/OurProducts";
import DentistRekommends from "../../images/forpakningsbilder/Forpackningarna_3-13.jpg";
import OurHistoryPicture from "../../images/produktbilder/Tandborstar_gron_why_bambu.jpg";
import WhyBambuPicture from "../../images/produktbilder/Tandborstar_lila_var_historia.jpg";
import Context from "../../context/context";

const pageContent = [
  {
    page: "ourProducts",
    heading: { sv: "Våra Produkter", eng: "Our products" },
    text: {
      sv: `Upptäck ett miljövänligt alternativ av hög kvalitet och låt dina barn använda EasyFairy bambutandborstar varje dag! Vår FSC-certifierade bambu är en oändligt förnybar resurs och 100% biologiskt nedbrytbar. Designen och storleken är anpassade efter barns behov med mjuka borststrån tillverkade i nylon-4 helt utan BPA och PVC. Skaftet är infärgat i miljövänlig certifierad naturfärg, vilket skyddar bambun vid väta. Förpackningen är 100% plastfri med färg i sojaprint. 
    
Gör tandborstningen till en god vana - inte bara för ditt barns munhälsas skull utan även för vår planet. Välj EasyFairy bambutandborste varje dag.`,
      eng: `Discover a high-quality, eco-friendly alternative and let your child use an EasyFairy bamboo toothbrush every day. Our FSC-certified bamboo is an infinitely renewable resource and 100% biodegradable. Design and the size of the brush are adapted to children's needs with soft bristles made of BPA- and PVC-free nylon-4. The colour of the handle is an environmentally friendly certified natural colour, which protects the bamboo when it gets wet. The packaging is 100% plastic free with soy print colour. EasyFairy comes in three different sizes for diffrent age groups.

Make your toothbrushing a good daily habit - not only for your child's oral health, but also for our planet. Choose the EasyFairy bamboo toothbrush every day.`,
    },
    picture: varaProdukterBild,
    products: [
      {
        heading: {
          sv: "3-5 år",
          eng: "3-5 years",
        },
        text: {
          sv: `Barns mun och tänder förändras mycket under denna tid. Det är också under tidiga år i livet som man sätter den viktiga vanan att borsta tänderna. 
        
Vår ambition är att tandborstningen ska vara rolig och en naturlig vana. Våra tandborstar för barn i åldern 3-5 år har ett kortare handtag som passar den lilla handen (men som också kan borstas av en vuxen), ett litet borsthuvud med färgglada mjuka borststrån. Tandborstarna finns i tre olika färger: rosa, lila och turkos.`,
          eng: `Children's mouths and teeth change a lot during this age. It is also during the early years of life the important habit of brushing the teeth is established. 

Our ambition is  to make toothbrushing fun and a natural habit. Our toothbrushes made for children aged 3-5 years have a shorter handle that fits the little hand (but can also be used by an adult) and a small brush head with colourful soft bristles. The toothbrush is decorated with a cute bunny face and is available in three different colours: pink, purple and turquoise.`,
        },
        picture: productImage35,
      },
      {
        heading: {
          sv: "6-9 år",
          eng: "6-9 years",
        },
        text: {
          sv: `En händelserik period med luckor och en blandning av mjölktänder och vuxentänder! Allt eftersom mjölktänderna ersätts av vuxentänder blir det lite trängre i munnen. Kanske kommer tandfen på besök och det är en spännande tid… 
        
Tandfen EasyFairy kommer förstås inte med några mynt, men med ett miljövänligt alternativ med tandborstar i naturlig bambu som samtidigt är roliga och i glada färger. EasyFairy 6-9 år har ett mellankort skaft anpassat efter barnets hand (men som också kan borstas av en vuxen), ett mindre borsthuvud med färgglada mjuka borststrån. De finns i tre olika färger: rosa, lila och grön.`,
          eng: `The period in life when kids start loosing teeth which makes gaps and a mixture of baby teeth and adult teeth! As the baby teeth are replaced by adult teeth, the mouth becomes little by little more crowded. 
Maybe you have a pleasant visit from the tooth fairy… 

Of course, the EasyFairy tooth fairy doesn't bring any coins, but with an environmentally friendly alternative with our natural bamboo toothbrushes in happy colours.  EasyFairy 6-9 years has a medium-short handle adapted to the child's hand (but can also be used by an adult) and a smaller brush head with colourful soft bristles. The toothbrush is decorated with a cute bunny face and available in three different colours: pink, purple and green. `,
        },
        picture: productImage69,
      },
      {
        heading: { sv: "10-13 år", eng: `10-13 years` },
        text: {
          sv: `Fler vuxentänder börjar ta plats i munnen och det blir allt mindre plats. Nu kommer utmaningen att komma åt mellan tänderna! EasyFairy 10-13 år har koniska borststrån, dvs spetsiga, vilket gör det lättare att komma åt mellan tänder som sitter extra tätt. 
        
De koniska borststråna är dessutom skonsamma mot tandköttet. Det går nästan inte att borsta för hårt! Våra tandborstar för barn i åldern 10-13 år har ett lite längre skaft, men fortfarande något kortare än en vuxentandborste och ett mellanstort borsthuvud med färgglada mjuka koniska borststrån. De finns i tre olika färger: rosa, turkos och blå.`,
          eng: `More adult teeth show up and there is less room left in the mouth. Now the challenge is to clean between the teeth! EasyFairy 10-13 years has conical bristles, i.e. pointed, which makes it easier to clean between teeth sitting close together. 

The conical bristles are also gentle on the gums and they give a soft feeling of massage. Our toothbrushes for children aged 10-13 have a longer handle than our other models and a medium-sized brush head with colorful soft conical bristles. The toothbrush is decorated with a cute bunny face and available in three different colours: pink, turquoise and blue.`,
        },
        picture: productImage1013,
      },
    ],
  },
  {
    page: "ourIcon",
    heading: {
      sv: "Vår ikon",
      eng: "Our icon",
    },
    text: {
      sv: `EasyFairy är vår fe och medvetna ikon som med sitt trollspö sprider hållbara leenden över världen! Att ta hand om den enda planet vi fått borde vara lika roligt, enkelt och självklart som att borsta tänderna! 
    
Ju fler val vi gör i vardagen som är miljömedvetna desto större skillnad åstadkommer vi tillsammans. 

Skaften på våra tandborstar är av naturlig bambu från FSC-verifierad skog och borststråna BPA- och PVC-fri nylon-4

Våra olika glada färger är certifierade och naturliga utan mineraloljor eller kemiska tillsatser. Med EasyFairy bambutandborstar rengör du dina tänder och är samtidigt snäll mot naturen. Vår tandfe EasyFairy uppmanar dig att göra din tandborstning till en god gärning, inte bara för din egen munhälsa utan också för vår planet.`,
      eng: `Our purple flying EasyFairy is our own tooth fairy and conscious icon who uses her magic wand to spread sustainable smiles around the world! 
Taking care of the only planet we have should be as fun, easy and natural as brushing your teeth. 

The more choices we make in our everyday life that are environmentally conscious, the larger difference we make together. 

The handles of our toothbrushes are made of natural bamboo from FSC-verified forests and the bristles are BPA- and PVC-free, pure nylon-4 (10-13 years have nylon-6). 

Our colours are certified and natural without mineral oils or chemical additives. With EasyFairy bamboo toothbrushes you clean your teeth at the same time as you are kind to nature. 
Our tooth fairy EasyFairy invites you to make brushing your teeth a good deed, not only for your own oral health but also for our planet.`,
    },
    picture: icon,
  },
  {
    page: "ourStory",
    heading: { sv: "Vår historia", eng: "Our Story" },
    text: {
      sv: `Tänk dig butiksytan med tandborstar i din lokala matbutik. Minst 90% av hyllorna upptas av tandborstar av plast, eller hur? Varför är det inte tvärtom, att miljövänliga tandborstar av bambu upptar större delen av ytan när fördelarna för miljön är så många?

Samma tankar uppkommer vid granskning av förpackningarna – så gott som alla delvis gjorda av plast. Inte våra! Idén till EasyFairy kom någon gång under år 2020 när miljömedvetenheten hos många människor varit stor ett tag, men kom i skymundan för det världsomspännande farliga Corona-viruset. 

Tänk om vi kunde få bidra till en stor positiv förändring för miljön, utan att människor skulle behöva engagera sig. Med ett ben i babybranschen och det andra med erfarenhet från tandvårdsindustrin föddes EasyFairy, en bambutandborste som är lika färgglad som de i plast men som samtidigt tar hänsyn till miljön. 

Två år senare var produkten klar, EasyFairy tillverkas av FSC-certifierad Moso-bambu från Kina med giftfria strån och certifierad naturlig färg. Den svenska designen är färgglad och passar barn i olika åldrar och förpackningen är 100% plastfri med färg i sojaprint. Du hittar våra produkter hos utvalda apotek och barnbutiker.`,
      eng: `Imagine the toothbrush section in your local grocery store. At least 90% of the shelf space is covered by plastic toothbrushes. 
Why isn’t it the environmental friendly alternative e.g. bamboo toothbrushes, that takes most space on the shelves instead? And have you ever thought of the packaging material? Almost every one of them partly made by plastic… 

Not ours! We choose the environmental friendly alternative, bamboo toothbrushes in a simple paper packaging because we believe this is the future.

The EasyFairy idea was born during 2020. Sustainability had been top of mind for many people for a couple of years but this year it was a bit unprioritized because of the Covid-19 pandemic.

What if there was an easy way to contribute to a better world, something most people can do without sacrifying anything. With our expertize in the baby business together with experience in the dental care industry, EasyFairy was born, a bamboo toothbrush that is as colourful as the plastic ones but takes the environment into account. 

Two years later, the product was ready, EasyFairy is made from FSC-certified Moso bamboo from China with non-toxic bristles and certified natural colour. 
The Swedish design is playful and suitable for children of different ages and the packaging is 100% plastic-free with colour in soy print. Our products are sold at selected pharmacies and children's stores.`,
    },
    picture: OurHistoryPicture,
  },
  {
    page: "whyBambu",
    heading: {
      sv: "Varför bambu?",
      eng: "Why bamboo?",
    },
    text: {
      sv: `För att bambu är det gröna alternativet för den medvetna konsumenten! Väljer du en tandborste av bambu väljer du ett miljövänligt alternativ som har lägre påverkan på vår planet än plasttandborstarna – men du rengör dina tänder lika bra! 

EasyFairy är tillverkade av naturlig Moso-bambu från FSC-verifierad skog i Fujian-provinsen i sydöstra Kina. Det innebär att tandborsten är helt vegansk och biologiskt nedbrytbar. Bambu är ett naturligt antibakteriellt material och en av de snabbast växande växter i världen.  Inga bekämpningsmedel eller kemikalier behövs för att odlingarna ska frodas. I vår tillverkningsprocess är det viktigt att ta hänsyn till alla inblandade, både natur, djur och arbetsförhållandena för människorna. 

Bambun vi använder odlas specifikt för tillverkning av bambuprodukter och utgör inte något hot mot pandor eller den biologiska mångfalden i Kina.`,

      eng: `Because bamboo is the green alternative for all concious customers. When buying a bamboo toothbrush you have chosen an enviromentally friendly alterative that affect the world less than the plastic toothbrushes – but the cleaning result is the same. 
      
EasyFairy are made from natural Moso bamboo from FSC-verified forests in the Fujian province in the south of China. This means that the toothbrush is completely vegan and biodegradable. 
      
Bamboo is a natural antibacterial material and one of the fastest growing plants in the world. No pesticides or chemicals are needed for the bamboo plants to flourish. 
      
In our manufacturing process, it is important to take everything into account, nature, animals and the working conditions of the people. The bamboo we use is grown specifically for the manufacture of bamboo products and does not pose a threat to pandas or the biodiversity of China.`,
    },
    picture: WhyBambuPicture,
  },
  {
    page: "dentistRecommends",
    heading: {
      sv: "Tandläkaren rekommenderar",
      eng: "Dentist recommendations",
    },
    text: {
      sv: `Borsta barnets tänder med fluortandkräm två gånger om dagen. När det görs skapas en god vana, med tandborstning som en del av rutinen vilket minskar risken att barnet får hål i tänderna och andra tandhälsoproblem senare i livet. Det är viktigt att börja sköta barnets tänder tidigt. 
Småätande är inte bra för tänderna eftersom för mycket sötsaker inte bara kan leda till hål i tänderna utan också kan bidra till övervikt och följdsjukdomar senare i livet, som diabetes, hjärt- och kärlsjukdomar. Lördagsgodis är ett beprövat recept, håll dig till det och ge hellre några fruktbitar än en bulle eller söt kaka som snacks. Bjud på vatten om barnet är törstigt! (Källa: Sveriges tandläkarförbund.)

EasyFairy tandborstar är anpassade efter barns tänder och mun och finns i tre storlekar för olika åldrar och behov: 3-5 år (den rosa förpackningen), 6-9 år (den ljuslila förpackningen) och 10-13 år (den mörklila förpackningen).`,
      eng: `Brush your child's teeth with fluoride toothpaste twice a day. When this is done, a good habit is created, with tooth brushing as part of the routine, which reduces the risk of the child getting cavities and other dental health problems later in life. It is important to start caring for your child's teeth early. Snacking is not good for your teeth because too much sweets can not only lead to cavities, but can also contribute to obesity and subsequent diseases later in life, such as diabetes or cardiovascular diseases. Candy on Saturdays only is well known - stick to it and give you child some fruit rather than food containing sugar as a snack. Offer water if the child is thirsty! (Source: Swedish Dental Association (Sveriges Tandläkarförbund) 

EasyFairy toothbrushes are adapted to children's teeth and mouths and are available in three sizes for different ages and needs: 3-5 years (the pink packaging), 6-9 years (the light purple packaging) and 10-13 years (the dark purple packaging). 
`,
    },
    picture: DentistRekommends,
  },
  {
    page: "about",
    heading: {
      sv: "Om Oss",
      eng: "About us",
    },
    text: {
      sv: `EasyFairy är ett svenskt varumärke ägt av Kid Number 4, som säljer barnartiklar via återförsäljare. Bolaget grundades 2019 av föräldraduon Louise och Gustav Berntorp. Kid Number 4 marknadsför produkter som underlättar småbarnsåren på olika sätt. Vår payoff är vår filosofi: Happy Kid. Happy You. 
    
Målgruppen är småbarnsföräldrar och många söker efter ekologiska och hållbara produkter. Bambutandborstarna från EasyFairy lever upp till dessa önskemål. De är samtidigt designade så att de tilltalar barn med sina glada färger och gulliga kaninprint. Vi vill hjälpa till att sprida hållbara leenden över världen!

EasyFairy och våra andra produkter säljs hos utvalda babybutiker och apotek i Sverige: Babyland, Babyworld, Stor & Liten, Apotea, Apotek Hjärtat, Apohem och Meds Apotek.`,
      eng: `EasyFairy is a Swedish brand owned by Kid Number 4 who sells children's products through retailers. The company was founded in 2019 by the parent duo Louise and Gustav Berntorp.  Kid Number 4 has a mix of products that facilitate the early childhood years in various ways. Our payoff is our philosophy: Happy Kid. Happy You.
      
The target group is parents of babies and young children. Many families are looking for organic and sustainable products and the bamboo toothbrushes from EasyFairy live up to these wishes. They are designed to appeal to children with their cheerful colours and cute bunny print at the same time as they are enviromentally friendly. Buy EasyFairy and help us spread sustainable smiles around the world! 

EasyFairy and our other products are sold at selected baby stores and pharmacies in Sweden. 
`,
    },
    picture: loggo,
  },
  {
    page: "b2b",
    heading: { sv: "B2B", eng: "B2B" },
    text: {
      sv: `EasyFairy bambutandborstar säljs via utvalda barnbutiker samt apotek. Vill du bli en av våra återförsäljare av EasyFairy? 
    
Välkommen att maila oss på <a href="mailto:info@kidnumber4.com">info@kidnumber4.com</a>`,
      eng: `EasyFairy bamboo toothbrushes are to be found in selected children’s stores and pharmacies. Are you interested in becoming a retailer? 

Please contact us <a href="mailto:info@kidnumber4.com">info@kidnumber4.com</a>`,
    },
  },
  {
    page: "contact",
    heading: {
      sv: "Kontakt",
      eng: "Contact",
    },
    text: {
      sv: `Har du frågor eller önskemål till oss om våra produkter är du välkommen att maila oss!
    
Mail:
info@kidnumber4.com

Adress: 
Kid Number 4
Klockarevägen 43
236 36 Höllviken
Sverige

Organisationsnummer:
559190-0906
    `,
      eng: `If you have questions or requests about our products, please send us an email. 

E-mail:
info@kidnumber4.com

Address:
Kid Number 4
Klockarevägen 43
236 36 Höllviken
Sweden

Org. no:
559190-0906
`,
    },
    picture: loggo,
  },
];

const PageInfoFetcher = (props) => {
  const {language} = useContext(Context);
  const [fetchResult, setFetchResult] = useState();
  const [pageTemplate, setPageTemplate] = useState();

  const getPageInfo = async () => {
    const data = await fetchResult.json();
    return data;
  };

  useEffect(() => {
    const pageObject = pageContent.filter((pc) => pc.page === props.page)[0];

      if (props.page === "about" || props.page === "b2b" || props.page === "contact" && window.scrollTop !== 0){
        window.scrollTo(0, 0);
      }

     setPageTemplate(
       <div>
         {
           <PageTemplate
            page={props.page}
             header={
               language === "sv"
                 ? pageObject.heading.sv
                 : pageObject.heading.eng
             }
             text={language === "sv" ? pageObject.text.sv : pageObject.text.eng}
             imageUrl={pageObject.picture}
           />
         }
         {props.page === "ourProducts" && (
           <OurProducts products={pageContent[0].products} />
         )}
       </div>
     );
  }, [props, language])

  return <div>{pageTemplate}</div>;
};

export default PageInfoFetcher;
