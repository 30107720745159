import React from "react";
import classes from "./Product.module.css";

const Product = React.forwardRef((props, theRef) => {
  return (
    <div className={classes.product} ref={theRef}>
      <img src={props.descPicture} />
      <div className={classes.productDesc}>
        <div>{props.descHeading}</div>
      <div>{props.descColor}</div></div>
    </div>
  );
});

export default Product;
