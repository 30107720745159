import classes from "./SlideShowImage.module.css";
const  SlideShowImage = (props) => {
  const classesSlideShow = [
    props.current === props.imageShown ? classes.slideActive : classes.slide,
    classes.fade,
  ];

  return (
    <div className={classesSlideShow.join(" ")}>
      <img
        src={props.image}
        alt="Test"
      />
    </div>
  );
};

export default SlideShowImage;
