import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import CompanyLogo from "../CompanyLogo/CompanyLogo";
import LanguageSelection from "../LanguageSelection/LanguageSelection";
import MenuButton from "../Navigation/MenuButton";
import Navigation from "../Navigation/Navigation";
import SlideShow from "../SlideShow/SlideShow";
import classes from "./Header.module.css";
const Header = () => {
  const {pathname} = useLocation();
  const [showSlideShow, setShowSlideShow] = useState(true);
  const [isMenuShown, setIsMenuShown] = useState(false);
  useEffect(() => {
    setShowSlideShow(() => pathname.slice(1) === "landing");
  }, [pathname])

  const menuButtonClickHandler = () => {}
  return (
    <header className={classes.header}>
      <CompanyLogo />
      <LanguageSelection />
      <MenuButton clicked={() => setIsMenuShown((prev) => !prev)} />
      <Navigation
        isMenuOpen={isMenuShown}
        clicked={() => setIsMenuShown((prev) => false)}
      />
      {showSlideShow && <SlideShow />}
    </header>
  );
};

export default Header;
