import classes from "./Backdrop.module.css";


const Backdrop = (props) => {
    let attachedClasses = [classes.Backdrop, classes.Close];
    if(props.modalOpen){
        attachedClasses = [classes.Backdrop, classes.Open];
    }

    return <div onClick={props.closeModal} className={attachedClasses.join(" ")}></div>;
}

export default Backdrop;